import { useQuery } from "@apollo/client"
import React from "react"
import { GOODS_LIST } from "../../query/goods"
import { LoadingProducts } from "./LoadingProducts"
import { Product } from "./Product"

export const SelectedProducts = ({ type, catData, fileData }) => {
  let cat
  let page = 1
  let paging = 4

  let orderBy = []
  orderBy.push({ updated: "DESC" })

  if (type === "cat") {
    cat = catData?.url
  }
  if (type === "bestsellers") {
    paging = 16
  }
  if (type === "new") {
    paging = 8
    cat = "novinky"
  }

  const { loading, data } = useQuery(GOODS_LIST, {
    variables: {
      cat: cat ?? null,
      itemsPerPage: paging,
      page: page,
      top: type === "bestsellers" ? "1" : null,
      orderBy: orderBy,
    },
  })

  let items = data?.goods.collection || []
  let newItems = []
  items &&
    items.forEach((item, itemIdx) => {
      let imgData
      fileData.allProduct.nodes.forEach(node => {
        if (parseInt(node.productId) === item._id) {
          imgData = node.localFile
        }
      })
      let newProduct = {
        ...item,
        imgData: imgData,
      }
      newItems.push(newProduct)
    })
  items = newItems

  return (
    <>
      {items && items.length === 0 && loading && <LoadingProducts />}
      <div className="mt-6 grid grid-cols-2 gap-y-8 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8 max-w-7xl mx-auto  px-5">
        {items &&
          items.length > 0 &&
          items.map((item, itemIdx) => (
            <Product
              key={itemIdx}
              item={item}
              list={items}
              itemIdx={itemIdx}
              hasMore={false}
              lastElementRef={null}
            />
          ))}
      </div>
    </>
  )
}
