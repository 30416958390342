import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import { SelectedProducts } from "../components/goods/SelectedProducts"
import { ChevronRightIcon } from "@heroicons/react/solid"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "../client/apollo"

const IndexPage = ({ location }) => {
  const fileData = useStaticQuery(graphql`
    query FileQueryIndex {
      allProduct {
        nodes {
          id
          productId
          name
          localFile {
            childImageSharp {
              gatsbyImageData(height: 220)
            }
          }
        }
      }
      scomos {
        p1: pageItem(id: "/api/page_items/3") {
          content
        }
        p2: pageItem(id: "/api/page_items/5") {
          content
        }
      }
    }
  `)

  const boxes = fileData?.scomos
  const upozorneni = boxes.p1.content
  const praveJidlo = boxes.p2.content

  let query = "goods/items/"
  let page = 1

  let path
  let cat
  path = location.pathname.split("/")
  if (path[2] !== "strana" && path[2] !== "detail") {
    cat = path[2]
  }

  if (path[2] === "strana") {
    page = parseInt(path[3])
  }
  if (path[3] === "strana") {
    page = parseInt(path[4])
  }

  let orderBy = []
  orderBy.push({ updated: "DESC" })

  let params = []
  params.push("priceVat:>:0")

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Švédské potraviny FÖDA</title>
      </Helmet>

      <ApolloProvider client={apolloClient}>
        <main className="pb-8 relative">
          <div className="w-full h-full  overflow-hidden absolute z-0  ">
            <StaticImage
              src={"../images/moose4.jpg"}
              alt="Švédské lahůdky s rozvozem po Praze"
              className="  object-cover   h-full lg:w-full "
            />
          </div>
          <div className="absolute h-1/3 bg-gradient-to-b from-black to-transparent w-full " />
          <div className="relative z-20 lg:pb-20">
            <div>
              <div className="max-w-7xl mx-auto py-16 pt-8 ">
                <div className=" rounded-lg  overflow-hidden lg:grid lg:grid-cols-2 ">
                  <div className=" pb-12 px-6   lg:pr-0  ">
                    <div className="lg:self-center">
                      <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                        <span className="block">Švédské lahůdky</span>
                      </h2>
                      <p className="  text-lg leading-6 text-white ">
                        s rozvozem po Praze
                      </p>
                    </div>
                  </div>
                  <div className="  aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1"></div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="  text-center py-3 border-t border-white  px-5 bg-yellow-400">
          <div
            className="text-xl px-8 font-bold mx-auto max-w-7xl"
            dangerouslySetInnerHTML={{
              __html: upozorneni,
            }}
          />
        </div>
        <div className="mt-16 max-w-7xl mx-auto  px-5">
          <div className="text-xl px-8 font-bold">Novinky</div>

          <SelectedProducts type={"new"} fileData={fileData} />
        </div>

        <main className="my-10 relative">
          <div className="w-full h-full  overflow-hidden absolute z-0  ">
            <StaticImage
              src={"../images/banner-1.png"}
              alt="Švédské lahůdky s rozvozem po Praze"
              className="  object-cover   h-full lg:w-full "
            />
          </div>
          <div className="absolute  inset-0 bg-black bg-opacity-10 w-full " />
          <div className="relative z-20 lg:pb-20">
            <div>
              <div className="max-w-7xl mx-auto py-16 pt-8 ">
                <div className=" rounded-lg  overflow-hidden lg:grid lg:grid-cols-2 ">
                  <div className=" pb-12 px-6   lg:pr-0  ">
                    <div
                      className="lg:self-center p-10 prose"
                      dangerouslySetInnerHTML={{
                        __html: praveJidlo,
                      }}
                    />
                  </div>
                  <div className="  aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1"></div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="mt-16 max-w-7xl mx-auto  px-5">
          <div className="text-xl px-8 font-bold">Nejprodávanější</div>
          <SelectedProducts type={"bestsellers"} fileData={fileData} />
        </div>
      </ApolloProvider>
    </>
  )
}

export default IndexPage
